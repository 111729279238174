.navbar {
  padding: 0 1.5rem 0 3rem;
  z-index: 1001;
  background: linear-gradient(90deg, #4b6cb7, #182848);

  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-menu {
    flex: 99999 1;
    padding-left: 30px;
  }
  .right-menu {
    flex: 0 0;
    .ant-menu-item:last-child {
      padding-right: 0;
    }
  }
  .navbar-ham {
    display: none;
    color: white;
    font-size: 1.71rem;
  }

  .logo {
    flex: 0 0;

    img {
      // max-width: 41px;
      height: 41px;
      padding-bottom: 0.25rem;
    }
  }

  .ant-menu-dark {
    background: transparent;
    // flex: 0.1 1;
    display: flex;
    align-items: center;

    .ant-menu-item {
      padding: 9px 20px;
      :nth-child(4) {
        svg * {
          stroke: white !important;
        }
      }
      a {
        font-size: 14px;
        letter-spacing: 0.3px;
        color: white;
        font-family: "Poppins-Medium";
      }

      svg * {
        stroke: transparent;
      }

      .user-name {
        text-transform: capitalize;
      }

      .user-status-tag {
        margin-left: 0.25rem;
      }

      .anticon {
        font-size: 20px;
        // padding-top: 1rem;
        margin-right: 0;
        svg {
          position: relative;
          top: 2px;
        }
      }

      .ant-avatar {
        background-color: #fc4747;
        border: 2px solid white;
        box-sizing: content-box;
        text-transform: capitalize;
        font-size: 1.57rem;
      }
      .avatar-details {
        display: inline-block;
        margin: 0 0.75rem;
        font-size: 0.93rem;
        line-height: 20px;
        vertical-align: middle;
        text-transform: capitalize;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 0.625rem;
        .domain-name {
          font-size: 0.78rem;
        }
      }
    }
    .ant-menu-item-selected,
    .ant-menu-item-active,
    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu.ant-menu-dark .ant-menu-item-active,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active {
      background: #18284861;
      color: white;
    }
  }
}
.navbar-user-dropdown {
  min-width: 205px;
  padding: 2.2px;
  p {
    margin-bottom: 0;
  }
  .current-account-details {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    .account-name {
      margin-right: 0.71rem;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 999;
    }
    .ant-tag {
      border-radius: 9px;
      margin-right: 0;
    }
  }
  .switch-account-label {
    font-size: 0.85rem;
    margin-top: 0.71rem;
  }
  .ant-dropdown-menu-item {
    font-size: 1rem;
    padding: 15px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    .anticon {
      margin-left: 8px;
      display: none;
    }
  }
  .ant-dropdown-menu-item:hover {
    color: #182848 !important;
    background: #1828484f;
    .anticon {
      display: inline-block;
    }
  }
}
.ant-dropdown-link {
  svg {
    font-size: 13px;
  }
}
li.ant-menu-item.report-icon svg * {
  stroke: white !important;
}
@media (max-width: 768px) {
  .header .ant-menu {
    display: none;
  }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right;
  }
}
