.form-container {
  padding: 5rem 2rem 0 2rem;
  height: 100%;

  .logo {
    margin: auto;
    width: 13rem;
    display: block;
  }

  .tagline {
    text-align: center;
    margin-top: 0.7rem;
    font-weight: 300;
  }

  .content {
    padding-top: 3rem;

    .heading {
      color: #4b6cb7;
      font-size: 1.43rem;
      text-align: center;
      font-weight: 400;
    }

    .heading-separator {
      border-color: #4b6cb7;
      margin: auto;
      width: 45%;
      margin-bottom: 1.5rem;
    }

    .ant-form-item {
      width: 80%;
      margin: auto;
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    .ant-input {
      border-color: #d9d9d9;
    }

    .flex-div {
      .ant-form-item-children {
        display: flex;
        font-size: 1rem;
        flex-wrap: wrap;
      }

      .gap {
        flex: 1;
      }
    }
  }

  .footer {
    position: sticky;
    top: 100%;
    padding-bottom: 30px;

    .links {
      display: flex;
      margin-left: 30%;
      width: 50%;

      a {
        flex: 1;
      }
    }

    .copyright-text {
      margin-left: 32%;
      width: 50%;
    }
  }
}
